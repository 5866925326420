<template>
  <div id="footer">
    <div class="footer-content" style="border-bottom: 1px solid #999;padding-bottom: 30px">
      <div class="page-content-wrap">
        <div class="spec-list" style="">
          <div class="spec-item">
            <img src="../../assets/images/footer/gg@2x.png" alt="">
            <span style="color: #D9262C">规格齐全</span>
          </div>
          <div class="spec-item">
            <img src="../../assets/images/footer/jh@2x.png" alt="">
            <span style="color: #D9262C">极速交货</span>
          </div>
          <div class="spec-item">
            <img src="../../assets/images/footer/pz@2x.png" alt="">
            <span style="color: #D9262C">品质保证</span>
          </div>
          <div class="spec-item">
            <img src="../../assets/images/footer/tx@2x.png" alt="">
            <span style="color: #D9262C">贴心价格</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-content">
      <div class="page-content-wrap" style="padding-bottom: 30px">
        <div class="main-content">
          <div class="links">
            <div v-for="(item, index) in classifyData" :key="index" class="links-box">
              <div class="link-title">{{ item.name }}</div>
              <div class="link-list">
                <div v-for="(val, key) in item.acticle" :key="key" class="link-item">
                  <router-link class="nav-item" :to="`/help/acticle/${val.id}`">{{ val.name }}</router-link>
                </div>
              </div>
            </div>
            <!-- <div class="links-box">
              <div class="link-title">关于鹏隆</div>
              <div class="link-list">
                <div class="link-item">
                  <router-link class="nav-item" to="/">使用须知</router-link>
                </div>
                <div class="link-item">
                  <router-link class="nav-item" to="/">网站使用协议</router-link>
                </div>
                <div class="link-item">
                  <router-link class="nav-item" to="/">关于企业认证</router-link>
                </div>
              </div>
            </div> -->
            <!-- <div class="links-box">
              <div class="link-title">帮助指南</div>
              <div class="link-list">
                <div class="link-item">
                  <router-link class="nav-item" to="/">使用指南</router-link>
                </div>
                <div class="link-item">
                  <router-link class="nav-item" to="/">新手教学</router-link>
                </div>
                <div class="link-item">
                  <router-link class="nav-item" to="/">常见问题</router-link>
                </div>
              </div>
            </div> -->
            <!-- <div class="links-box">
              <div class="link-title">客户服务</div>
              <div class="link-list">
                <div class="link-item">
                  <router-link class="nav-item" to="/">快速选型</router-link>
                </div>
                <div class="link-item">
                  <router-link class="nav-item" to="/">退换货服务</router-link>
                </div>
                <div class="link-item">
                  <router-link class="nav-item" to="/">如何快速报价</router-link>
                </div>
                <div class="link-item">
                  <router-link class="nav-item" to="/">意见反馈</router-link>
                </div>
              </div>
            </div> -->
          </div>
          <div class="contact" style="border-left: 1px solid #999">
            <div class="left">
              <p>受理时间：上午8:00-12:00 下午13:30-17:30</p>
              <p>邮件：penglong@dgpenglong.com</p>
              <p class="kefu">
                <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=800090801&site=qq&menu=yes">
                  <img src="../../assets/images/footer/kf@2x.png" alt="">
                  <span>: </span>
                  <span style="margin-left: 10px;">在线客服</span>
                </a>
              </p>
            </div>
            <div class="right">
              <img src="../../assets/images/footer/f195ede4e87f074bda6b231fdac09085.jpg" alt="" class="code">
              <div style="text-align: center;margin-top: 6px">扫码关注我们</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="page-content-wrap">
        <span>Copyright © 2020 广东鹏隆自动化科技有限公司 版权所有</span>
        <span>广东鹏隆自动化科技有限公司</span>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">ICP备案号:粤ICP备2020130733号</a>
        <!-- <a href="https://beian.miit.gov.cn/" target="_blank">公网备案号:粤ICP备2020130733</a> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      classifyData: []
    }
  },
  created() {
    this.getClassifyData()
  },
  methods: {
    async getClassifyData() {
      const res = await this.$http.get('api/acticle/classify', { page: 1, per_page: 4, type: 1 })
      this.classifyData = res.data
    }
  }
}
</script>
<style lang="scss" scoped>
#footer {


  .footer-content {
    padding-top: 20px;
    background-color: #f5f5f5ff;

    //padding: 30px 0;
    //color: #ffffff;
    .spec-list {
      display: flex;
      justify-content: space-between;

      .spec-item {
        display: flex;
        align-items: center;

        span {
          font-size: 18px;
        }

        img {
          height: 46px;
          margin-right: 10px;
        }
      }
    }

    .main-content {
      padding-top: 30px;
      display: flex;
      justify-content: space-between;

      .links {
        width: 50%;
        border-right: 1px solid #ffffff;
        display: flex;

        .links-box {
          flex: 1;

          .link-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 40px;
          }

          .link-list {
            .link-item {
              line-height: 40px;

              a {
                text-decoration: none;
                //color: #ffffff;
                color: #666;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      .contact {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          padding-left: 70px;

          p {
            margin-bottom: 20px;
          }

          .kefu {
            width: 132px;
            height: 40px;
            border-radius: 4px;
            //border: 1px solid #ffffff;
            color: #333;
            margin-bottom: 50px;

            a {
              display: block;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              text-decoration: none;
              color: #333;
              //justify-content: space-around;
              //padding: 0 10px;
              box-sizing: border-box;
            }
          }
        }

        .right {
          .code {
            width: 125px;
          }
        }
      }
    }
  }

  .copyright {
    background-color: #ecececff;
    border-bottom: 1px solid #999;
    border-top: 1px solid #999;
    text-align: center;

    span,
    a {
      color: #333;
      margin-right: 15px;
      line-height: 54px;
      text-decoration: none;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
