<template>
  <div id="app">
    <router-view :key="$route.meta.refresh ? $route.path + $route.query.t : ''" />
    <Footer />
    <SideBar />
  </div>
</template>
<script>
import Footer from '@/components/footer/index.vue'
import SideBar from '@/components/sidebar/index.vue'
export default {
  components: {
    Footer,
    SideBar
  }
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Microsoft YaHei, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  font-size: 14px;
  line-height: 1;
}

.page-content-wrap {
  width: 1200px;
  margin: 0 auto;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.address-add {
  .el-input__inner {
    border-radius: 2px;
    border-color: #e6e6e6;

    &:focus {
      border-color: #D9262c !important;
    }
  }
}

.goods-detail {
  .filter-item {
    .select-box {

      // div.el-input {
      //   width: auto;
      //   max-width: 200px;
      // }
      .el-input__icon {
        line-height: 30px;
      }

      .el-input__inner {
        color: #3385FF;
        border-radius: 1px;
        padding: 0 10px;
        height: 30px;
        line-height: 28px;
        font-size: 14px;

        &:focus {
          border-color: #3385FF;
        }

        &::-webkit-input-placeholder {
          color: rgba(51, 133, 255, .5);
        }

        &:-moz-placeholder {
          /* FF 4-18 */
          color: rgba(51, 133, 255, .5);
        }

        &::-moz-placeholder {
          /* FF 19+ */
          color: rgba(51, 133, 255, .5);
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: rgba(51, 133, 255, .5);
        }
      }
    }
  }
}

.pl-store__quote-table-wrap {
  .el-table__body tr:hover>td {
    background-color: #F3E9E9 !important;
  }

}

.regist-container .page-wrap .page-content-wrap .regist-form .agree .iconfont.icon-checkbox-xuanzhong[data-v-048d7ff6] {
  color: #D9262C !important;
}

.el-select-dropdown__item, .el-table__row {
  &:hover {
    background: #f5f5f5 !important;
    td {
      background: #f5f5f5 !important;
    }
  }
}

.el-select-dropdown__item.hover {
	background: #f5f5f5 !important;
}

input:-webkit-autofill { box-shadow: 0 0 0px 1000px #f5f5f5 inset !important;}

.el-message--success {
	background-color: #fef0f0 !important;
	border-color: #fde2e2 !important;
}

.el-message .el-icon-success {
	color: #F56C6C !important;
}

.el-message--success .el-message__content {
	color: #F56C6C !important;
}
</style>
