import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2'
// 引入图标字体
import './assets/iconfont/iconfont.css'
// http请求
import httpRequest from './utils/request'
// 按需引入element-ui组件
import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css'
import Router from 'vue-router'
import './element-variables.scss'
// 解决重复点击导航路由报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  // console.log(location)
  return originalPush.call(this, location).catch(err => err);
}
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueClipboard)
Vue.prototype.$http = httpRequest

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
