<template>
  <div class="buttons">
    <div @click="jumpShopCar" class="button shopcar" style="display:flex;">
      <div>
        <img src="../../assets/images/common/shoppingIcon.png">
      </div>
      <div style="margin-left: 10px;">购物车</div>
      <div style="margin-left: 8px;background-color: #fdd000ff;border-radius: 8px;height: 17px;width: 26px;text-align: center;line-height: 17px;color: #000000">{{ shopCarNumber }}</div>
    </div>
    <!-- <div class="button jifen">
      <span class="icon iconfont icon-shangchengduihuan-"></span>
      <span>积分商城</span>
    </div> -->
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  data () {
    return {}
  },
  computed: mapState(['shopCarNumber']),
  methods: {
    jumpShopCar () {
      this.$router.push({ path: '/user/shopping' })
    }
  }
}
</script>
<style lang="scss" scoped>
.buttons {
  display: flex;
  align-items: center;
  .button {
    width: 140px;
    height: 44px;
    border: 1px solid #CACACAFF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // transition: all .3s;
    &:first-child {
      margin-right: 20px;
    }
    .iconfont {
      margin-right: 10px;
      font-size: 18px;
    }
    &.shopcar {
      border-color: #FDD000;
      //color: #3385FF;
      color: #FDD000;
      padding: 10px;
    }
    &:hover {
      //border-color: #D9262C;
      color: rgba(253, 208, 0, .7) !important;
    }
  }
}
</style>
