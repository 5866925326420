<template>
  <div id="common-top">
    <div class="page-content-wrap">
      <a class="logo" href="/">
        <img src="../../assets/images/common/911387e1b94493c307fc08c5734540f3.png" alt="">
      </a>
      <Search :value="keywords" v-if="isShowSearch" />
      <Buttons v-else />
    </div>
  </div>
</template>
<script>
import Buttons from './buttons.vue'
import Search from './search.vue'
export default {
  props: {
    keywords: {
      type: String,
      default: ''
    },
    isShowSearch: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Buttons,
    Search
  },
  methods: {
    jumpHome () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
<style lang="scss" scoped>
#common-top {
  background-color: #ffffff;
  padding: 15px 0;
  .page-content-wrap {
    display: flex;
    justify-content: space-between;
    .logo {
      padding-top: 20px;
      img {
        width: 235px;
        height: 68px;
        cursor: pointer;
      }
    }
  }
}
</style>
