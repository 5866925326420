<template>
	<div class="nav-container">
		<div class="cat-title">
			<img src="../../assets/images/common/fenlei.png" alt="">
			<span style="margin-left: 20px;">全部商品分类</span>
			<div :style="{ height: height ? height : ''}" v-if="isShow" class="page-content-wrap">
				<div class="cat-list">
					<div @click.stop="jumpCategoryList(item)" v-for="item in categoryList" :key="item.id" class="cat-item">
						<div class="cat-name">
							<!-- <span class="icon iconfont icon-fenlei"></span> -->
							<img :src="item.icon" alt="" class="icon">
							<span class="name">{{ item.name }}</span>
						</div>
						<span class="more icon iconfont icon-hangdongjiantou"></span>
						<div @click.stop="preventHandle" class="item-content">
							<div class="item-content-title">{{ item.name }}</div>
							<div class="item-content-list">
								<div
										@click.stop="jumpGoodsList(cat)" v-for="cat in item.children" :key="cat.id"
										class="item-content-list-item">{{ cat.name }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="nav-list">
			<div @click="jumpHandle('Home')" class="nav-item ">
				<span class="icon iconfont"></span>
				<!--        <span class="icon iconfont icon-home"></span>-->
				<span>商城首页</span>
			</div>
			<!--      <div @click="$router.push('/category/list?id=138')" class="nav-item ">-->
			<!--        <span class="icon iconfont "></span>-->
			<!--        <span class="icon iconfont icon-home"></span>-->
			<!--        <span>鹏隆专区</span>-->
			<!--      </div>-->
			<div @click="$router.push('/category/list?id=1')" class="nav-item ">
				<span class="icon iconfont"></span>
				<span>特惠专区</span>
			</div>
			<!--      <div @click="jumpHandle('userIndex')" class="nav-item ">-->
			<!--        <span class="icon iconfont icon-custom-user"></span>-->
			<!--        <span class="icon iconfont "></span>-->
			<!--        <span>个人中心</span>-->
			<!--      </div>-->
			<div class="nav-item " @click="$router.push(`/quote`)">
				<!--        <span class="icon iconfont icon-tubiao_mianfeibaojia"></span>-->
				<span class="icon iconfont "></span>
				<span>快速报价</span>
			</div>
			<div class="nav-item ">
				<el-dropdown>
          <span class="icon iconfont" style="color: #333">
            设计赋能<i class="el-icon-arrow-down el-icon--right" style="color: #D9262C"></i>
          </span>
					<el-dropdown-menu slot="dropdown" :append-to-body="false"  class="popper-dropdown">
						<el-dropdown-item
								v-for="item in articleClassifyList" :key="`articleClassifyList-${item.id}`"
								style="width: 140px;text-align: center" class="articleClassify"
						>
							<div
									@click="item.is_dir
									? $router.push('/news/' + item.type + '/' + item.id)
									:$router.push(`/news/acticle/` + item.page_id)">
								{{ item.name }}
							</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div class="nav-item " @click="$router.push(`/news/0`)">
				<!--        <span class="icon iconfont icon-xinwen"></span>-->
				<span class="icon iconfont"></span>
				<span>资讯中心</span>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		height: {
			type: String,
			default: ''
		},
		isShow: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			categoryList: [],
			articleClassifyList: []
		}
	},
	created() {
		this.getCategory()
		this.getArticleClassifyList()
	},
	methods: {
		// 通用跳转
		jumpHandle(name) {
			console.log(name)
			this.$router.push({name: name})
		},
		preventHandle() {
			return false
		},
		// 获取全部分类数据
		async getCategory() {
			const res = await this.$http.get('/common/category/tree', {})
			this.categoryList = res
		},
		// 跳转分类列表页
		jumpCategoryList(item) {
			this.$router.push({path: '/category/list', query: {id: item.id, t: new Date().getTime()}})
		},
		// 跳转商品列表页
		jumpGoodsList(cat) {
			this.$router.push({path: '/goods/list', query: {id: cat.id, t: new Date().getTime()}})
		},
		async getArticleClassifyList() {
			const {data} = await this.$http.get('/api/acticle/classify?page=1&per_page=3&type=3')
			this.articleClassifyList = data || []
			this.articleClassifyList.forEach(item => {
				if (item.name === '目录书下载') {
					item.page_id = 108
				} else if (item.name === '3D模型下载') {
					item.page_id = 109
				} else if (item.name === '产品知识库') {
					item.is_dir = true
				}
			})
		},
	}
}
</script>
<style lang="scss" scoped>
::v-deep {
  .popper__arrow {
    left: 46% !important;
	  top: -7px !important;
  }

}
//.popper__arrow::after {
//  border-bottom-color: #D9262C !important;
//}
.popper-dropdown {
	//min-width: 120px;
  //width: 100px;
	//position: absolute !important;
	//top: 25px !important;
	//left: -25px !important;
}


.leftBorder {
	border-left: 2px solid #333;
	//padding-left: 16px;
	//margin-left: 16px;
}

.el-dropdown-menu::v-deep {
	background-color: #fff;
  padding: 0;
	top: 20px !important;
	left: -12px !important;
}

.articleClassify {
	//background-color: #f5f5f5ff;
	background-color: #fff;
	width: 120px !important;

	&:hover {
		background-color: #D9262C !important;
		color: #fff !important;
	}
}

.nav-container {
	display: flex;
	//width: 100%;
	line-height: 40px;
	background-color: #fff;

	font-size: 18px;

	.iconfont {
		font-size: 19px;
	}

	.nav-list {
		display: flex;
		align-items: center;
		flex: 1;
		//justify-content: space-around;

		.nav-item {
			margin: 0 20px;
			cursor: pointer;
			padding-left: 20px;
			height: 24px;
			line-height: 24px;
			//font-weight: bold;
			//text-align: center;
			&:hover {
				color: #D9262C;
			}

			.iconfont {
				//margin-right: 10px;
				&.icon-home {
					font-size: 21px;
				}
			}
		}
	}

	.cat-title {
		width: 270px;
		display: flex;
		// justify-content: center;
		padding: 0 25px;
		align-items: center;
		cursor: pointer;
		background-color: #D9262C;
		border-radius: 2px 2px 0 0;
		color: #ffffff;

		&:hover {
			.page-content-wrap {
				height: 704px;
				width: 1200px;
			}

			//background-color: #D9262C;
		}

		.iconfont {
			font-size: 22px;
			margin-right: 16px;
		}

		position: relative;

		.page-content-wrap {
			pointer-events: none;
			height: 704px;
			transition: all .3s;
			//padding-top: 4px;
			//height: 0;
			overflow: hidden;
			position: absolute;
			top: 42px;
			left: 0;
			z-index: 888;
			width: 270px;

			.cat-list {
				pointer-events: auto;
				width: 270px;
				height: 100%;
				background-color: #fff;
				color: #333;
				font-weight: 500;

				.cat-item {
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 16px;
					padding: 0 25px;
					color: #333;
					line-height: 40px;
					position: relative;

					&:nth-child(n + 12) {
						.item-content {
							top: unset;
							bottom: 0;
						}
					}

					.item-content {
						position: absolute;
						left: 270px;
						top: 0;
						//width: 920px;
						background-color: #ffffff;
						width: 660px;
						padding: 20px;
						display: none;

						.item-content-list {
							color: #333333;
							display: flex;
							flex-wrap: wrap;
							margin-top: 5px;

							.item-content-list-item {
								margin-right: 15px;
								line-height: 1;
								margin-top: 15px;
								color: #666666;

								&:hover {
									color: #D9262C;
								}
							}
						}

						.item-content-title {
							font-size: 18px;
							color: #333;
							line-height: 1.1;
							padding-left: 12px;
							position: relative;

							&::after {
								display: block;
								content: "";
								height: 100%;
								width: 4px;
								background-color: #D9262C;
								position: absolute;
								left: 0;
								top: 50%;
								transform: translateY(-50%);
							}
						}
					}

					&:hover {
						background-color: #f9dfe0;
						color: #d9262c;

						.item-content {
							display: block;
						}

						.more {
							color: #666666;
							// display: block;
							opacity: 1;
							transform: translateX(0);
							transition: all .3s;
						}
					}

					.cat-name {
						display: flex;
						align-items: center;

						.icon {
							margin-right: 20px;
							width: 24px;
							height: 24px;
							object-fit: cover;
						}
					}

					.more {
						font-size: 14px;
						margin-right: 0;
						// display: none;
						opacity: 0;
						transform: translateX(-10px);
						transition: transform .5s;
					}
				}

			}
		}
	}
}
</style>
