import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/search/result',
    name: 'searchReault',
    component: () => import('../views/search/result.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/regist',
    name: 'regist',
    component: () => import('../views/login/regist.vue')
  },
  {
    path: '/bind',
    name: 'bind',
    component: () => import('../views/login/bind.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/user/index.vue'),
    children: [
      {
        path: 'index',
        name: 'userIndex',
        component: () => import('../views/user/home/index.vue'),
        meta: {
          needLogin: true
        }
      },
      {
        path: 'account/company',
        name: 'accountCompany',
        component: () => import('../views/user/account/company.vue'),
        meta: {
          needLogin: true
        }
      },
      {
        path: 'account/member',
        name: 'accountMember',
        component: () => import('../views/user/account/member.vue'),
        meta: {
          needLogin: true
        }
      },
      {
        path: 'collect',
        name: 'collect',
        component: () => import('../views/user/collect/index.vue'),
        meta: {
          needLogin: true
        }
      },
      // 我的报价单
      {
        path: 'quote',
        name: 'myquote',
        component: () => import('../views/user/quote/index.vue'),
        meta: {
          needLogin: true
        }
      },
      {
        path: 'quote/detail/:id',
        component: () => import('../views/user/quote/detail.vue'),
        meta: {
          needLogin: true
        }
      },
      // 购物车
      {
        path: 'shopping',
        component: () => import('../views/user/shoppingCart/index.vue'),
        meta: {
          needLogin: true
        }
      },
      {
        path: 'shopping/order',
        component: () => import('../views/user/shoppingCart/order.vue'),
        meta: {
          needLogin: true
        }
      },
      {
        path: 'shopping/pay',
        component: () => import('../views/user/shoppingCart/pay.vue'),
        meta: {
          needLogin: false
        }
      },
      {
        path: 'shopping/success',
        component: () => import('../views/user/shoppingCart/success.vue'),
        meta: {
          needLogin: true
        }
      },
      {
        path: 'shopping/fail',
        component: () => import('../views/user/shoppingCart/fail.vue'),
        meta: {
          needLogin: true
        }
      },
      {
        path: 'shopping/bank',
        component: () => import('../views/user/shoppingCart/bank.vue'),
        meta: {
          needLogin: true
        }
      },
      {
        path: 'message',
        component: () => import('../views/user/message'),
        meta: {
          needLogin: true
        }
      },
      // 发票管理
      {
        path: 'invoice',
        component: () => import('../views/user/invoice'),
        meta: {
          needLogin: true
        }
      },
      {
        path: 'invoice/make',
        component: () => import('../views/user/invoice/make.vue'),
        meta: {
          needLogin: true
        }
      },
      {
        path: 'invoice/confirm',
        component: () => import('../views/user/invoice/confirm.vue'),
        meta: {
          needLogin: true
        }
      },
      // 订单
      {
        path: 'order',
        component: () => import('../views/user/order'),
        meta: {
          needLogin: true
        }
      },
      {
        path: 'order/detail',
        component: () => import('../views/user/order/detail.vue'),
        meta: {
          needLogin: true
        }
      },
      {
        path: 'order/sales',
        component: () => import('../views/user/order/sales.vue'),
        meta: {
          needLogin: true
        }
      },
    ]
  },
  {
    path: '/resetpassword',
    name: 'resetpasseord',
    component: () => import('../views/login/resetPassword')
  },
  {
    path: '/category/list',
    name: 'categoryList',
    component: () => import('../views/list/category.vue'),
    meta: {
      refresh: true
    }
  },
  {
    path: '/goods/list',
    name: 'goodsList',
    component: () => import('../views/list/goods.vue'),
    meta: {
      refresh: true
    }
  },
  {
    path: '/goods/recommend',
    name: 'goodsRecommend',
    component: () => import('../views/goods/recommend.vue'),
    meta: {
      refresh: true
    }
  },
  {
    path: '/goods/detail',
    name: 'goodsDetail',
    component: () => import('../views/goods/detail.vue')
  },
  {
    path: '/goods/download',
    name: 'goodsDownload',
    component: () => import('../views/goods/download.vue')
  },
  {
    path: '/goods/quotation',
    name: 'goodsQuotation',
    component: () => import('../views/goods/quotation/index.vue')
  },
  {
    path: '/quote/:upload?',//upload 上传文件报价
    name: 'quoteIndex',
    component: () => import('../views/quote/index.vue'),
    meta: {
      needLogin: true
    }
  },
  {
    path: '/news/:active?/:classifyId?',//0 新闻， 2公告
    name: 'news',
    component: () => import('../views/news/index.vue'),
    meta: {
      needLogin: false
    },
    children: [
      {
        path: 'acticle/:id',
        component: () => import('../views/news/acticle.vue')
      },
      {
        path: '',
        component: () => import('../views/news/list.vue')
      },
    ]
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/help/index.vue'),
    meta: {
      needLogin: false
    },
    children: [
      {
        path: '',
        component: () => import('../views/help/list.vue')
      },
      {
        path: 'acticle/:id',
        component: () => import('../views/help/acticle.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})
router.beforeEach(async (to, from, next) => {
  console.log('Store.state.hotKeyWords', Store.state.hotKeyWords)
  if (!Store.state.hotKeyWords.length)
    await Store.dispatch('setHotKeyWords')
  // console.log(to)
  if (to.name === 'login') {
    if (to.query.thirdparty_id && to.query.thirdparty) {
      next({ name: 'bind', query: { thirdparty_id: to.query.thirdparty_id, thirdparty: to.query.thirdparty } })
    } else if (to.query.token) {
      localStorage.setItem('token', to.query.token)
      next({ name: 'Home' })
    } else {
      next()
    }
  } else if (to.meta.needLogin) {
    await Store.dispatch('setUserInfo')
    await Store.dispatch('setCarNumber')
    next()
  } else {
    const token = localStorage.getItem('token')
    if (token) {
      await Store.dispatch('setCarNumber')
    }
    next()
  }
})
export default router
