import Vue from 'vue'
import Vuex from 'vuex'
import httpRequest from '../utils/request'
import {getHotKeywordsList, getShopingList} from '@/api/shoping.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    shopCarNumber: 0,
    hotKeyWords: []
  },
  mutations: {
    setUserInfo (state, userInfo) {
      // console.log(userInfo)
      state.userInfo = userInfo
    },
    setCarNumber (state, number) {
      state.shopCarNumber = number
    },
    setHotKeyWords (state, hotKeyWords) {
      state.hotKeyWords = hotKeyWords
    }
  },
  getters: {
    getHotKeywordsList(state) {
      return state.hotKeyWords
    }
  },
  actions: {
    async setCarNumber ({ commit }) {
      const res = await getShopingList({ page: 1, per_page: 1})
      console.log(res, '购物车列表数据')
      commit('setCarNumber', res.total)
    },
    async setHotKeyWords ({ commit }) {
      const res = await getHotKeywordsList({ limit: 5 })
      console.log(res, '购物车列表数据-222')
      commit('setHotKeyWords', res)
    },
    async setUserInfo ({ commit }, userInfo) {
      if (userInfo) {
        commit('setUserInfo', userInfo)
      } else {
        const res = await httpRequest.get('/api/userinfo', {})
        commit('setUserInfo', res)
      }

    },
    async signout ({ commit }) {
      await httpRequest.post('/api/logout', {})
      localStorage.clear()
      commit('setUserInfo', {})
    }
  },
  modules: {
  }
})
